// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advertisements-js": () => import("./../../../src/pages/advertisements.js" /* webpackChunkName: "component---src-pages-advertisements-js" */),
  "component---src-pages-alkebulan-js": () => import("./../../../src/pages/alkebulan.js" /* webpackChunkName: "component---src-pages-alkebulan-js" */),
  "component---src-pages-care-package-js": () => import("./../../../src/pages/carePackage.js" /* webpackChunkName: "component---src-pages-care-package-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logos-js": () => import("./../../../src/pages/logos.js" /* webpackChunkName: "component---src-pages-logos-js" */),
  "component---src-pages-nowhere-js": () => import("./../../../src/pages/nowhere.js" /* webpackChunkName: "component---src-pages-nowhere-js" */)
}

